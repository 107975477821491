<template>
    <v-app>
        <v-container id="update-panel" class="fill-height">
            <v-card class="pa-5" elevation="10" color="#ffffff">
                <v-row justify="center">
                    <v-slide-y-transition appear>
                        <template v-slot:heading>
                            <div class="text-center">
                                <h1 class="display-2 font-weight-bold mb-2">Alterar senha</h1>
                            </div>
                        </template>

                        <v-card-text class="text-center card">

                            <v-card-title class="justify-center title-card">
                                <figure class="back-icon" title="Voltar" alt="Voltar para login">
                                    <img    src="@/assets/icons/arrow_back_ios.svg"
                                            alt="Botão voltar"
                                            @click="toLogin" />
                                </figure>
                                <v-icon large color="red">vagas-home</v-icon>
                                <h2>Altere sua senha</h2>
                            </v-card-title>

                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row class="flex-column" align="start">
                                    <v-col class="d-flex flex-column align-start py-0">
                                        <span class="text-fields">E-mail</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field   v-model="username"
                                                        placeholder="willie.jennings@example.com"
                                                        :rules="[(v) => !!v || 'E-mail requerido']"
                                                        @keydown.enter="update"
                                                        color="secondary"
                                                        :messages="msgLabel"
                                                        required
                                                        outlined
                                                        rounded
                                                        autocomplete="email"
                                                        class="input" />
                                    </v-col>
                                </v-row>

                                <v-row class="flex-column senha" align="start">
                                    <v-col class="d-flex flex-column align-start py-0">
                                        <span class="text-fields">Senha atual</span>
                                    </v-col>
                                    <v-col>
                                        <v-text-field   v-model="password"
                                                        placeholder="********"
                                                        :rules="passwordRules"
                                                        @keydown.enter="update"
                                                        color="secondary"
                                                        required
                                                        outlined
                                                        rounded
                                                        class="input"
                                                        autocomplete="password"
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        @click:append="show = !show" />
                                    </v-col>
                                </v-row>

                                <v-row class="flex-column senha" align="start">
                                    <v-col class="d-flex flex-column align-start py-0">
                                        <span class="text-fields">Nova senha e confirmação</span>
                                    </v-col>
                                    <v-col class="packed">
                                        <v-text-field   v-model="newPassword"
                                                        placeholder="********"
                                                        :rules="passwordRules"
                                                        @keydown.enter="update"
                                                        color="secondary"
                                                        required
                                                        outlined
                                                        rounded
                                                        class="input"
                                                        autocomplete="password"
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        @click:append="show = !show" />
                                    </v-col>
                                    <v-col class="packed">
                                        <v-text-field   v-model="passconfirm"
                                                        placeholder="********"
                                                        :rules="passwordRules"
                                                        @keydown.enter="update"
                                                        color="secondary"
                                                        class="input"
                                                        required outlined rounded
                                                        autocomplete="confirmation"
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        @click:append="show = !show" />
                                    </v-col>
                                    <v-col>
                                        <v-btn          :disabled="!valid"
                                                        large rounded
                                                        color="success"
                                                        @click="update"
                                                        :loading="loading"
                                                        class="btn-login">
                                            <p style="color: white; margin-bottom: 0px">Atualizar</p>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>

                            <v-btn class="back-button" text small @click="toLogin">
                                Voltar ao login
                            </v-btn>
                        </v-card-text>
                    </v-slide-y-transition>
                </v-row>
            </v-card>
        </v-container>
    </v-app>
</template>

<style scoped>
#update-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--principal-azul);
  max-width: unset;
  max-height: unset;
}
.title-card {
  font-size: 0.8rem;
  margin: 0 0 1rem 0;
  padding-top: 0 !important;
}
.title-card > h2 {
  font-weight: 500 !important;
}
.card {
  padding: 1.5rem 1.5rem 1.5rem !important;
  width: 400px;
}
.text-fields {
  font-size: 1.2em;
}
.back-button {
  color: var(--principal-azul);
  font-size: 12px;
  font-weight: 200;
  text-transform: unset;
  transition: 500ms;
}
.back-button:hover {
  font-size: 0.8rem;
  transition: 500ms;
}
.packed {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 3.75rem;
    max-height: 3.75rem;
}
.back-icon {
  display: flex;
  cursor: pointer;
  margin-right: 1rem;
}
.btn-login {
  margin-top: 0 !important;
  width: 100%;
  background-color: var(--principal-azul) !important;
  padding: 1.7rem 0 !important;
}
</style>

<script lang="js">
import ApiService from "@/services/ApiService.js";

export default {
    props               : {
    },
    data                : (() => ({
        api             : undefined,
        loading         : false,
        show            : false,
        valid           : true,
        username        : undefined,
        password        : undefined,
        newPassword     : undefined,
        passconfirm     : undefined,
        passwordRules   : [(v) => !!v || "Senha requerida"],
        msgLabel        : ""
    })),
    components          : {
    },
    methods             : {
        validate        () {
            return this.$refs.form.validate()
        },
        matchingPwds    () {
            if (this.newPassword !== this.passconfirm) {
                this.msgLabel = 'Nova senha e confirmação são diferentes!';
                return false;
            }
            return true;
        },
        async update    () {
            this.loading            = true;
            try {
                if (!(this.matchingPwds() && this.validate())) {
                    return;
                }
                const model = {
                    id              : this.username,
                    currentPassword : this.password,
                    newPassword     : this.newPassword
                };
                const resp          = await this.api.post(
                    "user/password/update/offline",
                    model);
                this.$toast.success('Senha alterada com sucesso');
                setTimeout(() => this.toLogin(), 1000);
            } catch (e) {
                this.$toast.error(e.message);
            } finally {
                this.loading        = false;
            }
        },
        toLogin         () {
            this.$router.push('/auth');
        }
    },
    created             () {
        this.$toast.info("Senha expirada, altere sua senha para continuar.");
    },
    mounted             () {
        this.api        = new ApiService();
    }
}
</script>