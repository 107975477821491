var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { id: "update-panel" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-5",
              attrs: { elevation: "10", color: "#ffffff" },
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-slide-y-transition",
                    {
                      attrs: { appear: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "heading",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "display-2 font-weight-bold mb-2",
                                  },
                                  [_vm._v("Alterar senha")]
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center card" },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "justify-center title-card" },
                            [
                              _c(
                                "figure",
                                {
                                  staticClass: "back-icon",
                                  attrs: {
                                    title: "Voltar",
                                    alt: "Voltar para login",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/icons/arrow_back_ios.svg"),
                                      alt: "Botão voltar",
                                    },
                                    on: { click: _vm.toLogin },
                                  }),
                                ]
                              ),
                              _c(
                                "v-icon",
                                { attrs: { large: "", color: "red" } },
                                [_vm._v("vagas-home")]
                              ),
                              _c("h2", [_vm._v("Altere sua senha")]),
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "flex-column",
                                  attrs: { align: "start" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start py-0",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-fields" },
                                        [_vm._v("E-mail")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input",
                                        attrs: {
                                          placeholder:
                                            "willie.jennings@example.com",
                                          rules: [
                                            (v) => !!v || "E-mail requerido",
                                          ],
                                          color: "secondary",
                                          messages: _vm.msgLabel,
                                          required: "",
                                          outlined: "",
                                          rounded: "",
                                          autocomplete: "email",
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.update.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function ($$v) {
                                            _vm.username = $$v
                                          },
                                          expression: "username",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "flex-column senha",
                                  attrs: { align: "start" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start py-0",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-fields" },
                                        [_vm._v("Senha atual")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input",
                                        attrs: {
                                          placeholder: "********",
                                          rules: _vm.passwordRules,
                                          color: "secondary",
                                          required: "",
                                          outlined: "",
                                          rounded: "",
                                          autocomplete: "password",
                                          "append-icon": _vm.show
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show ? "text" : "password",
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.update.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          "click:append": function ($event) {
                                            _vm.show = !_vm.show
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "flex-column senha",
                                  attrs: { align: "start" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start py-0",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-fields" },
                                        [_vm._v("Nova senha e confirmação")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "packed" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input",
                                        attrs: {
                                          placeholder: "********",
                                          rules: _vm.passwordRules,
                                          color: "secondary",
                                          required: "",
                                          outlined: "",
                                          rounded: "",
                                          autocomplete: "password",
                                          "append-icon": _vm.show
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show ? "text" : "password",
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.update.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          "click:append": function ($event) {
                                            _vm.show = !_vm.show
                                          },
                                        },
                                        model: {
                                          value: _vm.newPassword,
                                          callback: function ($$v) {
                                            _vm.newPassword = $$v
                                          },
                                          expression: "newPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { staticClass: "packed" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "input",
                                        attrs: {
                                          placeholder: "********",
                                          rules: _vm.passwordRules,
                                          color: "secondary",
                                          required: "",
                                          outlined: "",
                                          rounded: "",
                                          autocomplete: "confirmation",
                                          "append-icon": _vm.show
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show ? "text" : "password",
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.update.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          "click:append": function ($event) {
                                            _vm.show = !_vm.show
                                          },
                                        },
                                        model: {
                                          value: _vm.passconfirm,
                                          callback: function ($$v) {
                                            _vm.passconfirm = $$v
                                          },
                                          expression: "passconfirm",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-login",
                                          attrs: {
                                            disabled: !_vm.valid,
                                            large: "",
                                            rounded: "",
                                            color: "success",
                                            loading: _vm.loading,
                                          },
                                          on: { click: _vm.update },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                color: "white",
                                                "margin-bottom": "0px",
                                              },
                                            },
                                            [_vm._v("Atualizar")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "back-button",
                              attrs: { text: "", small: "" },
                              on: { click: _vm.toLogin },
                            },
                            [_vm._v(" Voltar ao login ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }